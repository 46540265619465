import React, {
  RefObject,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import IAnswerResultModel from "@app/features/Lesson/domain/models/IAnswerResultModel";

import Drawer, { IDrawerRef } from "@app/components/molecules/Drawer";
import { Subtitle, Headline } from "@app/components/atoms/Text";
import { QUESTION_TYPE } from "@app/constants/enums";
import theme from "@app/config/theme";
import { FiCheckCircle, FiXCircle } from "react-icons/fi";

export type IDrawerResultRef = {
  openDrawer: () => void;
  closeDrawer: () => void;
};

type DrawerFilterProps = {
  ref?: RefObject<IDrawerResultRef>;
  data?: IAnswerResultModel[];
};

const DrawerResult = forwardRef<unknown, DrawerFilterProps>(
  ({ data }: DrawerFilterProps, ref) => {
    const drawerRef = useRef<IDrawerRef>();

    const openDrawer = useCallback(() => {
      drawerRef.current?.openDrawer();
    }, []);

    const closeDrawer = useCallback(() => {
      drawerRef.current?.closeDrawer();
    }, []);

    const getQuestionType = useCallback((answer: IAnswerResultModel) => {
      const { questionType } = answer;
      switch (questionType) {
        case QUESTION_TYPE.MULTIPLE_CHOICE:
          return "Multipla escolha";
        case QUESTION_TYPE.ORDERING:
          return "Ordenação";
        case QUESTION_TYPE.SPEAKING:
          return "Pronuncia";
        case QUESTION_TYPE.TEXT:
          return "Texto";
      }
    }, []);

    useImperativeHandle(ref, () => ({ openDrawer, closeDrawer }), [
      openDrawer,
      closeDrawer,
    ]);

    return (
      <Drawer ref={drawerRef} drawerPosition="right">
        <Box sx={{ pt: 12, pl: 2, pr: 2, width: "50vh", pb: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack spacing={2} direction="row">
                <Button variant="outlined" onClick={closeDrawer}>
                  Fechar
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={2} direction="row">
                <Headline>Respostas</Headline>
              </Stack>
            </Grid>
            <Grid item xs={12} alignItems="center" justifyContent="center">
              <Divider />
            </Grid>
            <Grid item xs={12} alignItems="center" justifyContent="center">
              {data?.map((item, index) => (
                <Card
                  sx={{
                    mb: 2,
                    backgroundColor: item.hit ? "#D2F4D3" : "#F5D3D2",
                  }}
                >
                  <CardContent>
                    <Chip label={getQuestionType(item)} color="primary" />
                    {item.hit ? (
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        sx={{ mt: 2, mb: 2 }}
                      >
                        <FiCheckCircle
                          color={theme.palette.success.main}
                          size={25}
                        />
                        <Subtitle
                          weight="600"
                          color={theme.palette.success.main}
                        >
                          Acertou
                        </Subtitle>
                      </Stack>
                    ) : (
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        sx={{ mt: 2, mb: 2 }}
                      >
                        <FiXCircle color={theme.palette.error.main} size={25} />

                        <Subtitle weight="600" color={theme.palette.error.main}>
                          Errou
                        </Subtitle>
                      </Stack>
                    )}

                    <Subtitle weight="600">{`${index + 1} - ${
                      item.description
                    }`}</Subtitle>
                    <Box sx={{ mt: 2, mb: !item.hit ? 2 : 0 }}>
                      <Typography variant="h6" component="div">
                        Resposta do aluno:
                      </Typography>
                      <Subtitle weight="600">{item.userAnswer}</Subtitle>
                    </Box>

                    {!item.hit && (
                      <>
                        <Typography variant="h6" component="div">
                          Resposta Correta:
                        </Typography>
                        <Subtitle weight="600">{item.correctAnswer}</Subtitle>
                      </>
                    )}
                    {!item.hit && (
                      <>
                        <Typography variant="h6" component="div">
                          Observação:
                        </Typography>
                        <Subtitle weight="600">{item?.observation}</Subtitle>
                      </>
                    )}
                  </CardContent>
                </Card>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    );
  }
);

export default DrawerResult;
