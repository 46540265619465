import { IUserRepository } from "@app/features/User/data/userRepository";

import IStudentLessonResultEntity from "@app/features/Student/data/IStudentProgressResultEntity";

import IStudentLessonResultModel from "@app/features/Student/domain/models/IStudentProgressResultModel";

const listStudentLessonsResultUseCase = async (
  repository: Pick<IUserRepository, "listUserLessonsResult">,
  userId: number,
  classeId: number
): Promise<IStudentLessonResultModel> => {
  const data = await repository.listUserLessonsResult(userId, classeId);
  return mapToDomain(data);
};

const mapToDomain = (
  data: IStudentLessonResultEntity
): IStudentLessonResultModel => {
  const lastExerciseDate = new Date(
    data.overview.lastExerciseDate
  ).toLocaleDateString("pt", {
    hour: "numeric",
    minute: "numeric",
  });

  return {
    ...data,
    overview: {
      ...data.overview,
      lastExerciseDate: lastExerciseDate,
    },
  };
};

export default listStudentLessonsResultUseCase;
