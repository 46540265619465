import { useCallback } from "react";

import { TCreateQuestionRemoteData } from "@app/services/question/remote-types/TCreateQuestionRemoteData";
import { TEditQuestionRemoteData } from "@app/services/question/remote-types/TEditQuestionRemoteData";
import IQuestionEntity from "@app/features/Question/data/IQuestionEntity";
import IGeneratedQuestionEntity from "@app/features/Question/data/IGeneratedQuestionEntity";

import { IQuestionService } from "@app/services/question";
import { AMOUNT_GENERATE_QUESTIONS } from "@app/constants/enums";

export interface IQuestionRepository {
  addQuestion(data: TCreateQuestionRemoteData): Promise<IQuestionEntity>;
  editQuestion(data: TEditQuestionRemoteData): Promise<void>;
  removeQuestion(lessonId: number): Promise<void>;
  generateQuestions(
    subject: string,
    amountQuestions: AMOUNT_GENERATE_QUESTIONS,
    languageId: number,
    fileKey?: string
  ): Promise<IGeneratedQuestionEntity[]>;
}

const useQuestionRepository = (
  questionService: IQuestionService
): IQuestionRepository => {
  const addQuestion = useCallback(
    async (data: TCreateQuestionRemoteData): Promise<IQuestionEntity> => {
      const question = await questionService.addQuestionService(data);
      return question;
    },
    [questionService]
  );

  const removeQuestion = useCallback(
    async (lessonId: number): Promise<void> => {
      await questionService.removeQuestionService(lessonId);
    },
    [questionService]
  );

  const editQuestion = useCallback(
    async (data: TEditQuestionRemoteData): Promise<void> => {
      await questionService.editQuestionService(data);
    },
    [questionService]
  );

  const generateQuestions = useCallback(
    async (
      subject: string,
      amountQuestions: AMOUNT_GENERATE_QUESTIONS,
      languageId: number,
      fileKey?: string
    ): Promise<IGeneratedQuestionEntity[]> => {
      const questions = await questionService.generateQuestionsService(
        subject,
        amountQuestions,
        languageId,
        fileKey
      );
      return questions;
    },
    [questionService]
  );

  return {
    addQuestion,
    removeQuestion,
    editQuestion,
    generateQuestions,
  };
};

export { useQuestionRepository };
