import { IQuestionRepository } from "@app/features/Question/data/questionRepository";
import IGeneratedQuestionEntity, {
  TGeneratedGenericAnswerModel,
  TGeneratedMultipleChoiceModel,
} from "@app/features/Question/data/IGeneratedQuestionEntity";
import { TQuestion, TQuestionForm } from "@app/features/Question/view/Form";
import { IMultipleChoiceModel } from "@app/features/Question/domain/models/IQuestionModel";

import { AMOUNT_GENERATE_QUESTIONS, QUESTION_TYPE } from "@app/constants/enums";

const generateQuestionsUseCase = async (
  repository: Pick<IQuestionRepository, "generateQuestions">,
  subject: string,
  amountQuestions: AMOUNT_GENERATE_QUESTIONS,
  languageId: number,
  fileKey?: string
) => {
  const result = await repository.generateQuestions(
    subject,
    amountQuestions,
    languageId,
    fileKey
  );
  const domain = mapToModel(result);

  return domain;
};

const mapToModel = (questions: IGeneratedQuestionEntity[]): TQuestionForm => {
  const questionsDomain = questions.map((question): TQuestion => {
    const { description: questionDescription, questionType, data } = question;

    switch (questionType) {
      case QUESTION_TYPE.ORDERING:
      case QUESTION_TYPE.SPEAKING:
      case QUESTION_TYPE.TEXT:
        return {
          data: data as TGeneratedGenericAnswerModel,
          description: questionDescription,
          questionType,
        };

      default:
        const answersDomain = (data as TGeneratedMultipleChoiceModel[]).map(
          (option): IMultipleChoiceModel => option
        );

        return {
          data: { answers: answersDomain },
          description: questionDescription,
          questionType,
        };
    }
  });

  return { questions: questionsDomain };
};

export default generateQuestionsUseCase;
