import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import GaugeChart from "react-gauge-chart";
import Markdown from "react-markdown";

import { useUserRepository } from "@app/features/User/data/userRepository";
import { useLessonRepository } from "@app/features/Lesson/data/lessonRepository";

import { useEditStudentViewModel } from "@app/features/Student/view/Edit/editStudentViewModel";

import StudentForm, { TStudentForm } from "@app/features/Student/view/Form";

import userService from "@app/services/user";
import lessonService from "@app/services/lesson";

import DrawerResult, {
  IDrawerResultRef,
} from "@app/components/organisms/DrawerResult";
import LoadingLock from "@app/components/molecules/LoadingLock";
import StandardTemplate from "@app/components/templates/Standard";
import FooterActions from "@app/components/molecules/FooterActions";
import StudentLessonAccordion from "@app/components/organisms/StudentLessonAccordion";
import TabPanel from "@app/components/templates/TabPanel";
import { Headline, Title } from "@app/components/atoms/Text";

import { RootState } from "@app/config/store";
import { ROLE } from "@app/constants/enums";
import EmptyState from "@app/components/organisms/EmptyState";
// import theme from "@app/config/theme";
import CountUp from "react-countup";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EditStudent = () => {
  const { id } = useParams();

  const { currentClasse } = useSelector((state: RootState) => state.classe);
  const { currentCompany } = useSelector((state: RootState) => state.company);

  const [currentTab, setCurrentTab] = useState(0);
  const drawerResultRef = useRef<IDrawerResultRef>();

  const userRepository = useUserRepository(userService);
  const lessonRepository = useLessonRepository(lessonService);

  const {
    getUser,
    listUserLessonsResult,
    editUserStatus,
    getAnswerFromStudentPage,
    answers,
    isLoading,
    isLoadingOverview,
    student,
    studentLessonsResult,
  } = useEditStudentViewModel(userRepository, lessonRepository);

  const handleChangeCurrentTab = useCallback(
    (_: React.SyntheticEvent<Element, Event>, value: number) => {
      setCurrentTab(value);
    },
    []
  );

  const handleSave = useCallback(
    (data: TStudentForm) => {
      if (currentClasse && currentCompany) {
        editUserStatus({
          classeId: currentClasse.id,
          companyId: currentCompany.id,
          roleName: ROLE.STUDENT,
          userId: Number(id),
          userStatusClasse: data.userStatusClasse,
          expireAt: data.expireAt,
        });
      }
    },
    [currentClasse, currentCompany, editUserStatus, id]
  );

  const handlePress = useCallback(
    async (studentPageId: number) => {
      await getAnswerFromStudentPage(studentPageId);
    },
    [getAnswerFromStudentPage]
  );

  const formatDate = useCallback((date?: Date): string | undefined => {
    if (!date) {
      return undefined;
    }
    const stringDate = new Date(date);

    const day = String(stringDate.getDate()).padStart(2, "0"); // Adiciona o '0' se o número for menor que 10
    const month = String(stringDate.getMonth() + 1).padStart(2, "0"); // Janeiro é 0, então somamos 1
    const year = stringDate.getFullYear();

    const formatedDate = `${day}/${month}/${year}`;

    return formatedDate;
  }, []);

  useEffect(() => {
    if (answers?.length) {
      drawerResultRef.current?.openDrawer();
    }
  }, [answers]);

  useEffect(() => {
    if (id) {
      getUser(Number(id));
    }
  }, [getUser, id]);

  useEffect(() => {
    if (id && currentClasse?.id) {
      listUserLessonsResult(Number(id), currentClasse.id);
    }
  }, [listUserLessonsResult, id, currentClasse?.id]);

  return (
    <>
      <StandardTemplate title="Aluno" showBackButton>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={currentTab}
            onChange={handleChangeCurrentTab}
            aria-label="basic tabs example"
          >
            <Tab label="Dados do aluno" {...a11yProps(0)} />
            <Tab label="Progresso do aluno" {...a11yProps(0)} />
          </Tabs>

          <TabPanel value={currentTab} index={0}>
            <LoadingLock isLoading={isLoading} />
            {student && (
              <StudentForm
                edit
                dataForm={{
                  ...student,
                  birthDate: formatDate(student?.birthDate),
                }}
                onValidateSuccess={handleSave}
                footerActions={<FooterActions onSaveAndBack={() => null} />}
              />
            )}
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <LoadingLock isLoading={isLoadingOverview} />
            <Card>
              <CardContent>
                <Title>Visão geral do aluno</Title>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Grid item xs={6} sm={4}>
                    <GaugeChart
                      id="gauge-chart1"
                      nrOfLevels={3}
                      arcsLength={[0.3, 0.4, 0.3]}
                      colors={["#EA4228", "#F5CD19", "#5BE12C"]}
                      percent={studentLessonsResult?.overview.accuracyRate}
                      textColor="#000"
                      formatTextValue={(value) => `Média de acertos: ${value}%`}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Card>
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          Ultimo exercício feito em:
                        </Typography>
                        <Typography variant="h5" component="div">
                          {studentLessonsResult?.overview.lastExerciseDate}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  {/* <Grid item xs={12} sm={3}>
                    <Card>
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          Acessos à turma no mês
                        </Typography>
                        <Typography variant="h5" component="div">
                          <CountUp prefix="+" end={20} suffix=" alunos" />
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid> */}
                  <Grid item xs={12} sm={4}>
                    <Card>
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          Aulas completadas:
                        </Typography>
                        <Typography variant="h5" component="div">
                          <CountUp
                            end={
                              studentLessonsResult?.overview?.finishedLessons ||
                              0
                            }
                            suffix=" aula(s)"
                          />
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Card>
                      <CardContent>
                        <Alert severity="info">
                          <AlertTitle>Sugestão de estudo</AlertTitle>
                          <Markdown>
                            {
                              studentLessonsResult?.overview
                                .overviewOberservation
                            }
                          </Markdown>
                        </Alert>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            {!studentLessonsResult?.data.length && (
              <EmptyState message="Esse aluno ainda não fez nenhum exercício ainda." />
            )}
            {studentLessonsResult?.data.map((studentLesson) => {
              return (
                <Box key={studentLesson.moduleId} sx={{ mt: 2 }}>
                  <Headline>{`Módulo: ${studentLesson.moduleName}`}</Headline>

                  {studentLesson.pages.map((page) => (
                    <StudentLessonAccordion
                      key={page.pageId}
                      data={page}
                      onPress={handlePress}
                    />
                  ))}
                </Box>
              );
            })}
          </TabPanel>
        </Box>
      </StandardTemplate>
      <DrawerResult ref={drawerResultRef} data={answers} />
    </>
  );
};

export default EditStudent;
