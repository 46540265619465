import React, {
  forwardRef,
  RefObject,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { Box, Button, Toolbar } from "@mui/material";

import LessonSettingsForm, {
  ITableSettingsFormRef,
  TLessonSettingsForm,
} from "@app/features/Module/view/Form/LessonSettingsForm";

import Drawer, { IDrawerRef } from "@app/components/molecules/Drawer";
import theme from "@app/config/theme";

export type DrawerLessonSettingsRef = {
  openDrawer: (moduleId: number, lessonId: number) => void;
};

type DrawerSelectLessonsProps = {
  ref?: RefObject<DrawerLessonSettingsRef>;
  dataForm?: TLessonSettingsForm;
  edit: boolean;
  onValidateSuccess: (
    dataForm: TLessonSettingsForm,
    moduleId: number,
    lessonId: number
  ) => void;
};

const DrawerLessonSettings = forwardRef<unknown, DrawerSelectLessonsProps>(
  ({ onValidateSuccess, dataForm, edit }: DrawerSelectLessonsProps, ref) => {
    const drawerRef = useRef<IDrawerRef>();
    const tableSettingsFormRef = useRef<ITableSettingsFormRef>();

    const [moduleId, setModuleId] = useState<number>();
    const [lessonId, setLessonId] = useState<number>();

    const handleCancel = useCallback(() => {
      drawerRef.current?.closeDrawer();
    }, []);

    const openDrawer = useCallback((moduleId: number, lessonId: number) => {
      setModuleId(moduleId);
      setLessonId(lessonId);
      drawerRef.current?.openDrawer();
    }, []);

    const handleValidateSettings = useCallback(async () => {
      const data = await tableSettingsFormRef.current?.validate();
      if (data && moduleId && lessonId) {
        onValidateSuccess(data, moduleId, lessonId);
        drawerRef.current?.closeDrawer();
      }
    }, [onValidateSuccess, moduleId, lessonId]);

    useImperativeHandle(ref, () => ({ openDrawer }), [openDrawer]);

    return (
      <Drawer ref={drawerRef} drawerPosition="bottom" onClose={handleCancel}>
        <Box
          sx={{
            position: "sticky",
            overflow: "hidden",
            top: 0,
            zIndex: 9999,
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <Box>
              <Button
                variant="contained"
                color="inherit"
                size="small"
                onClick={handleCancel}
              >
                Cancelar
              </Button>

              <Button
                variant="contained"
                color="secondary"
                size="small"
                sx={{ ml: 2 }}
                onClick={handleValidateSettings}
              >
                Salvar
              </Button>
            </Box>
          </Toolbar>
        </Box>
        <Box
          sx={{ backgroundColor: "#FFF", height: "75vh", p: 3 }}
          role="presentation"
        >
          <LessonSettingsForm
            ref={tableSettingsFormRef}
            edit={edit}
            dataForm={dataForm}
          />
        </Box>
      </Drawer>
    );
  }
);

export default DrawerLessonSettings;
