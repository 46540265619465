import { useCallback, useState } from "react";

import { useNavigate } from "react-router-dom";

import getUserUseCase from "@app/features/User/domain/use-cases/getUserUseCase";
import updateUserStatusUseCase from "@app/features/User/domain/use-cases/updateUserStatusUseCase";
import listStudentLessonsResultUseCase from "@app/features/Student/domain/use-cases/listStudentLessonsResultUseCase";
import getAnswerFromStudentUseCase from "@app/features/Student/domain/use-cases/getAnswerFromStudentUseCase";

import IUserModel from "@app/features/User/domain/models/IUserModel";
import IAnswerResultModel from "@app/features/Lesson/domain/models/IAnswerResultModel";
import IStudentProgressResultModel from "@app/features/Student/domain/models/IStudentProgressResultModel";

import { IUserRepository } from "@app/features/User/data/userRepository";

import handleApplicationError from "@app/handlers/handleApiError";
import showNotification from "@app/components/molecules/Toast";
import { ROLE, USER_CLASSE_STATUS } from "@app/constants/enums";
import { ILessonRepository } from "@app/features/Lesson/data/lessonRepository";

export type TEditUserViewModel = {
  userId: number;
  userStatusClasse: USER_CLASSE_STATUS;
  roleName: ROLE;
  companyId: number;
  classeId: number;
  expireAt?: string;
};

const useEditStudentViewModel = (
  userRepository: IUserRepository,
  lessonRepository: ILessonRepository
) => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [isLoadingOverview, setLoadingOverview] = useState(false);
  const [student, setStudent] = useState<IUserModel>();
  const [studentLessonsResult, setStudentLessonsResult] =
    useState<IStudentProgressResultModel>();
  const [answers, setAnswers] = useState<IAnswerResultModel[]>();

  const editUserStatus = useCallback(
    async (data: TEditUserViewModel, shouldGoBack: boolean = true) => {
      try {
        setLoading(true);

        await updateUserStatusUseCase(
          {
            updateUserStatus: userRepository.updateUserStatus,
          },
          data
        );
        setLoading(false);
        showNotification("Dados alterados com sucesso", "SUCCESS");
        if (shouldGoBack) {
          navigate(-1);
        }
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [userRepository.updateUserStatus, navigate]
  );

  const getUser = useCallback(
    async (userId: number) => {
      try {
        setLoading(true);

        const result = await getUserUseCase(
          {
            getUser: userRepository.getUser,
          },
          userId
        );
        setStudent(result);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [userRepository.getUser]
  );

  const listUserLessonsResult = useCallback(
    async (userId: number, classeId: number) => {
      try {
        setLoadingOverview(true);

        const studentLessonsResult = await listStudentLessonsResultUseCase(
          {
            listUserLessonsResult: userRepository.listUserLessonsResult,
          },
          userId,
          classeId
        );
        setStudentLessonsResult(studentLessonsResult);
        setLoadingOverview(false);
      } catch (error) {
        setLoadingOverview(false);
        handleApplicationError.handleError(error);
      }
    },
    [userRepository.listUserLessonsResult]
  );

  const getAnswerFromStudentPage = useCallback(
    async (studentPageId: number) => {
      try {
        setAnswers([]);
        setLoading(true);

        const result = await getAnswerFromStudentUseCase(
          {
            getAnswerFromStudent: lessonRepository.getAnswerFromStudent,
          },
          studentPageId
        );
        if (!result.length) {
          showNotification("Essa não aula possui perguntas", "INFO");
        }
        setAnswers(result);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [lessonRepository.getAnswerFromStudent]
  );

  return {
    student,
    studentLessonsResult,
    isLoading,
    isLoadingOverview,
    answers,
    getUser,
    editUserStatus,
    listUserLessonsResult,
    getAnswerFromStudentPage,
  };
};

export { useEditStudentViewModel };
