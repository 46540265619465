import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FiCheck, FiPlus, FiRepeat } from "react-icons/fi";
import { Box } from "@mui/material";

import { useModuleRepository } from "@app/features/Module/data/moduleRepository";
import { useModulePageRepository } from "@app/features/ModulePage/data/modulePageRepository";

import { useListModuleViewModel } from "@app/features/Module/view/List/listModuleViewModel";

import moduleService from "@app/services/module";
import modulePageService from "@app/services/module-page";

import IModuleModel from "@app/features/Module/domain/models/IModuleModel";
import ILessonModel from "@app/features/Lesson/domain/models/ILessonModel";

import StandardTemplate from "@app/components/templates/Standard";
import LoadingLock from "@app/components/molecules/LoadingLock";
import ModuleAccordion, {
  onChangeModuleName,
} from "@app/components/organisms/ModuleAccordion";
import EmptyState from "@app/components/organisms/EmptyState";
import DrawerSelectLessons, {
  DrawerSelectLessonsRef,
} from "@app/components/organisms/DrawerSelectLessons";
import DrawerLessonSettings, {
  DrawerLessonSettingsRef,
} from "@app/components/organisms/DrawerLessonSettings";

import { IOrderModel } from "@app/constants/interfaces";
import { RootState } from "@app/config/store";
import { TLessonSettingsForm } from "@app/features/Module/view/Form/LessonSettingsForm";
import { RELEASE_DATE_TYPE } from "@app/constants/enums";

const ListModules = () => {
  const drawerSelectLessonsRef = useRef<DrawerSelectLessonsRef>();
  const drawerLessonSettingsRef = useRef<DrawerLessonSettingsRef>();
  const navigate = useNavigate();

  const [disableDragAndDrop, setDisableDragAndDrop] = useState(false);

  const moduleRepository = useModuleRepository(moduleService);
  const modulePageRepository = useModulePageRepository(modulePageService);

  const { currentClasse } = useSelector((state: RootState) => state.classe);

  const {
    modules,
    isLoading,
    lessonSettings,
    getLessonDetailFromModule,
    getModules,
    setModules,
    editModule,
    removeLessonFromModule,
    deleteModule,
    getLessonsFromModule,
    addLessonToModule,
    editLessonFromModule,
  } = useListModuleViewModel(moduleRepository, modulePageRepository);

  const handleClickCreateButton = useCallback(() => {
    navigate("/admin/modules/create");
  }, [navigate]);

  const handleClickCreateLesson = useCallback((module: IModuleModel) => {
    drawerSelectLessonsRef.current?.openDrawer(module.id);
  }, []);

  const handleOnEditLesson = useCallback(
    async (moduleId: number, lesson: ILessonModel) => {
      await getLessonDetailFromModule(moduleId, lesson.id);
      drawerLessonSettingsRef.current?.openDrawer(moduleId, lesson.id);
    },
    [getLessonDetailFromModule]
  );

  const onDragEnd = useCallback(
    (event: DropResult) => {
      if (!event.destination) return;
      const result = Array.from(modules);
      const [removed] = result.splice(event.source.index, 1);
      result.splice(event.destination.index, 0, removed);

      setModules([...result]);

      if (currentClasse) {
        editModule({
          id: currentClasse.id,
          reoder: {
            source: event.source.index + 1,
            destination: event.destination.index + 1,
          },
        });
      }
    },
    [currentClasse, modules, setModules, editModule]
  );

  const handleEditModuleName = useCallback(
    (data: onChangeModuleName) => {
      editModule(
        {
          id: data.moduleId,
          name: data.value,
        },
        data.onSuccessCallback
      );
    },
    [editModule]
  );

  const handleExpandModule = useCallback(
    (module: IModuleModel, isExpanded: boolean) => {
      if (isExpanded) {
        getLessonsFromModule(module.id);
      }
    },
    [getLessonsFromModule]
  );

  const handleDeleteLesson = useCallback(
    (module: IModuleModel, lesson: ILessonModel) => {
      removeLessonFromModule(module.id, lesson.id);
    },
    [removeLessonFromModule]
  );

  const handleClickSecondaryButton = useCallback(() => {
    setDisableDragAndDrop((v) => !v);
  }, []);

  const handleChangeOrderLesson = useCallback(
    (moduleId: number, lessonId: number, reoderEvent: IOrderModel) => {
      editLessonFromModule({
        lessonIds: [lessonId],
        moduleId,
        releaseDateType: RELEASE_DATE_TYPE.NONE,
        reoder: reoderEvent,
      });
    },
    [editLessonFromModule]
  );

  const handleEditLesson = useCallback(
    (data: TLessonSettingsForm, moduleId: number, lessonId: number) => {
      editLessonFromModule({
        moduleId,
        lessonIds: [lessonId],
        releaseDateType: data.releaseDateType,
        releaseAfterDay: data.releaseAfterDay,
        releaseDate: data.releaseDate,
      });
    },
    [editLessonFromModule]
  );

  const handleOnArchieveModule = useCallback(
    (module: IModuleModel) => {
      deleteModule({ id: module.id });
    },
    [deleteModule]
  );

  const handleOnSelectLessons = useCallback(
    (data: ILessonModel[], dataform: TLessonSettingsForm, moduleId: number) => {
      const lessonIds = data.map((lesson) => lesson.id);
      addLessonToModule({ ...dataform, lessonIds, moduleId });
    },
    [addLessonToModule]
  );

  useEffect(() => {
    if (currentClasse) {
      getModules(currentClasse.id);
    }
  }, [currentClasse, getModules]);

  return (
    <>
      <StandardTemplate
        title="Módulos"
        primaryButton={{
          isVisible: true,
          iconButton: <FiPlus />,
          textButton: "Novo módulo",
          onClickButton: handleClickCreateButton,
        }}
        secondaryButton={{
          textButton: disableDragAndDrop
            ? "Salvar ordenação"
            : "Reoedenar módulos",
          isVisible: !!modules.length && modules.length > 1,
          iconButton: disableDragAndDrop ? <FiCheck /> : <FiRepeat />,
          onClickButton: handleClickSecondaryButton,
        }}
      >
        {isLoading && <LoadingLock isLoading={isLoading} />}
        {!isLoading && !modules.length && (
          <EmptyState
            message="Você ainda não cadastrou nenhum módulo, seus alunos estão ansiosos pelo seus conteúdos.Para cadastrar o primeiro módulo clique no botão abaixo."
            onClickButton={handleClickCreateButton}
            textButton="Cadastrar módulo"
          />
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {modules.map((module, index) => (
                  <Draggable
                    key={module.id}
                    draggableId={String(module.id)}
                    isDragDisabled={!disableDragAndDrop}
                    index={index}
                  >
                    {(provided) => (
                      <Box mt={2} mb={2}>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ModuleAccordion
                            module={module}
                            disabled={disableDragAndDrop}
                            onChangeOrderLesson={handleChangeOrderLesson}
                            onEditLesson={handleOnEditLesson}
                            onDeleteLesson={handleDeleteLesson}
                            onExpandModule={handleExpandModule}
                            onCreateLesson={handleClickCreateLesson}
                            onChangeModuleName={handleEditModuleName}
                            onArchieveModule={handleOnArchieveModule}
                          />
                        </div>
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </StandardTemplate>

      <DrawerSelectLessons
        ref={drawerSelectLessonsRef}
        onSelectLessons={handleOnSelectLessons}
      />
      <DrawerLessonSettings
        ref={drawerLessonSettingsRef}
        edit
        dataForm={lessonSettings}
        onValidateSuccess={handleEditLesson}
      />
    </>
  );
};

export default ListModules;
