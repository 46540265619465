import React, {
  ChangeEvent,
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
} from "react";

import {
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { RELEASE_DATE_TYPE } from "@app/constants/enums";

export type TLessonSettingsForm = {
  releaseDateType: RELEASE_DATE_TYPE;
  releaseAfterDay?: number;
  releaseDate?: string;
  notify?: boolean;
};

export interface ITableSettingsFormRef {
  validate: () => Promise<TLessonSettingsForm>;
}

type TableSettingsFormProps = {
  ref?: RefObject<ITableSettingsFormRef>;
  edit: boolean;
  dataForm?: TLessonSettingsForm;
};

const TableSettingsForm = forwardRef(
  ({ dataForm, edit }: TableSettingsFormProps, ref) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
      watch,
      setValue,
      reset,
      trigger,
      getValues,
    } = useForm<TLessonSettingsForm>({
      resolver: yupResolver(
        yup.object().shape({
          releaseDateType: yup
            .mixed()
            .oneOf([
              RELEASE_DATE_TYPE.NONE,
              RELEASE_DATE_TYPE.BY_JOIN_USER_DATE,
              RELEASE_DATE_TYPE.BY_DATE,
            ])
            .optional(),
          releaseAfterDay: yup
            .number()
            .optional()
            .when("releaseDateType", {
              is: RELEASE_DATE_TYPE.BY_JOIN_USER_DATE,
              then: yup.number().required("Preencha o campo.").min(1),
              otherwise: yup.number().nullable(),
            }),
          releaseDate: yup
            .string()
            .optional()
            .when("releaseDateType", {
              is: RELEASE_DATE_TYPE.BY_DATE,
              then: yup.string().required("Preencha o campo."),
              otherwise: yup.string().nullable(),
            }),
          notify: yup.boolean().optional(),
        })
      ),
      defaultValues: {
        releaseDateType: RELEASE_DATE_TYPE.NONE,
        releaseAfterDay: 0,
        releaseDate: undefined,
        notify: true,
      },
      mode: "onSubmit",
    });

    const [releaseDateType, notify] = watch(["releaseDateType", "notify"]);

    const handleChangeReleaseAfterDate = useCallback(
      (_: ChangeEvent<HTMLInputElement>) => {
        setValue("releaseAfterDay", 0);

        if (releaseDateType === RELEASE_DATE_TYPE.BY_JOIN_USER_DATE) {
          setValue("releaseDateType", RELEASE_DATE_TYPE.NONE);
        } else {
          setValue("releaseAfterDay", undefined);
          setValue("releaseDate", undefined);
          setValue("releaseDateType", RELEASE_DATE_TYPE.BY_JOIN_USER_DATE);
        }
      },
      [setValue, releaseDateType]
    );

    const handleChangeReleaseDate = useCallback(
      (_: ChangeEvent<HTMLInputElement>) => {
        setValue("releaseDate", undefined);

        if (releaseDateType === RELEASE_DATE_TYPE.BY_DATE) {
          setValue("releaseDateType", RELEASE_DATE_TYPE.NONE);
        } else {
          setValue("releaseAfterDay", 0);
          setValue("releaseDateType", RELEASE_DATE_TYPE.BY_DATE);
        }
      },
      [setValue, releaseDateType]
    );

    const validate = useCallback(async () => {
      const value = await trigger();

      if (value) {
        return getValues();
      }
    }, [getValues, trigger]);

    useImperativeHandle(ref, () => ({ validate }), [validate]);

    const onSubmit = useCallback(() => {}, []);

    useEffect(() => {
      reset(dataForm);
    }, [reset, dataForm]);

    return (
      <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
        {!edit && (
          <>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <FormGroup>
                  <Stack direction="row" alignItems="center">
                    <FormControlLabel
                      control={
                        <Switch checked={notify} {...register("notify")} />
                      }
                      label="Notificar alunos"
                    />
                  </Stack>
                  <FormHelperText>
                    Seus alunos receberão uma notificação quando a aula for
                    adicionada no módulo.
                  </FormHelperText>
                </FormGroup>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 2, mb: 2 }} />
          </>
        )}
        <Grid container item xs={12} sm={12} spacing={2}>
          <Grid item xs={12} sm={12}>
            <FormLabel component="legend">Configuraçõe adicionais</FormLabel>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Card sx={{ minHeight: 260 }}>
              <CardContent>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          releaseDateType ===
                          RELEASE_DATE_TYPE.BY_JOIN_USER_DATE
                        }
                        onChange={handleChangeReleaseAfterDate}
                      />
                    }
                    label="Liberar após dias"
                  />
                </FormGroup>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Aula será liberada após os dias informados, baseado na data de
                  entrada do aluno na turma.
                </Typography>
                <TextField
                  id="releaseAfterDay"
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  label="Liberar após"
                  disabled={
                    releaseDateType !== RELEASE_DATE_TYPE.BY_JOIN_USER_DATE
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">dias</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.releaseAfterDay}
                  helperText={
                    (!!errors.releaseAfterDay &&
                      !!errors.releaseAfterDay.message) ||
                    "após entrada do aluno na turma"
                  }
                  {...register("releaseAfterDay")}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Card sx={{ minHeight: 260 }}>
              <CardContent>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={releaseDateType === RELEASE_DATE_TYPE.BY_DATE}
                        onChange={handleChangeReleaseDate}
                      />
                    }
                    label="Liberar por data"
                  />
                </FormGroup>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Aula será liberada em uma data especifica, independente da da
                  data de entrada do aluno.
                </Typography>
                <TextField
                  id="releaseDate"
                  type="date"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  label="Liberar aula em:"
                  disabled={releaseDateType !== RELEASE_DATE_TYPE.BY_DATE}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.releaseDate}
                  helperText={
                    !!errors.releaseDate && !!errors.releaseDate.message
                  }
                  {...register("releaseDate")}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </form>
    );
  }
);

export default TableSettingsForm;
